import cn from "../../util/cn.js";

interface Props {
  className?: string;
}

const FlexibleSpacer = ({ className }: Props) => {
  return <div className={cn(className, "flex-grow")}></div>;
};

export default FlexibleSpacer;
